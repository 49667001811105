import HomePage from '@/view/HomePage'
import React, {Fragment} from 'react'
import axios from '@/api/index'
import { NextPageContext } from 'next'
import Layout from '@/components/Layout'
import Head from 'next/head'

export default function Home({ data, isMobile}: any) {
  return (
    <Fragment>
      <Head>
        {data && data.seo && data.seo.title && data.seo.title.length ? (
          <title>{data.seo.title}</title>
        ) : null}
        {data && data.seo && data.seo.canonical && data.seo.canonical.length ? (
          <link rel="canonical" href={data.seo.canonical} />
        ) : null}
        {data &&
        data.seo &&
        data.seo.description &&
        data.seo.description.length ? (
          <meta name="description" content={data.seo.description}></meta>
        ) : null}
        <meta
          name="keywords"
          content={
            data && data.seo && data.seo.keywords && data.seo.keywords.length
              ? data.seo.keywords
              : ''
          }
        ></meta>
      </Head>
      <Layout pageInfo={data && data.pageInfo} isMobile={isMobile}>
        <HomePage data={data} isMobile={isMobile}></HomePage>
      </Layout>
    </Fragment>
  )
}

export async function getServerSideProps(context: NextPageContext) {
  const deviceAgent = context.req?.headers['user-agent']?.toLocaleLowerCase()
  const agentID = deviceAgent?.match(/(iphone|ipod|ipad|android)/);
  try {
    const { data } = await axios.get('/api/page/home', {
      params: {
        isMobile: !!agentID
      }
    })

    if (!data.data && data.code !== 0) {
      return {
        redirect: {
          destination: '/404',
          permanent: false,
        },
      }
    }

    return {
      props: { data: data.data, isMobile: !!agentID },
    }
  } catch (err) {
    return {
      redirect: {
        destination: '/error',
        permanent: false,
      },
    }
  }
}
