import HomeBanner from '@/components/Banner/HomeBanner'
import React, {useEffect} from 'react'
import dynamic from "next/dynamic";

const LogoListType1 = dynamic(()=>import('@/components/LogoList/type1'))
const LogoListType2 = dynamic(()=>import('@/components/LogoList/type2'))
const CouponType3 = dynamic(()=>import('@/components/Coupon/type3'))
const TextListType1 = dynamic(()=>import('@/components/TextList/type1'))
const EmailType1 = dynamic(()=>import('@/components/Email/type1'))

import styles from './index.module.scss'

export default function HomePage({ data, isMobile }: any) {
    const banners = [
        {
            img: "https://img.timelycoupons.com/banner/casper.webp",
            path: "/coupons/casper"
        },
        {
            img: "https://img.timelycoupons.com/banner/shopbop.webp",
            path: "/coupons/shopbop"
        },
        {
            img: "https://img.timelycoupons.com/banner/levis.webp",
            path: "/coupons/levis"
        },
        {
            img: "https://img.timelycoupons.com/banner/dsw.webp",
            path: "/coupons/dsw"
        },
        {
          img: "https://img.timelycoupons.com/banner/ebags.webp",
          path: "/coupons/ebags"
        },
        {
          img: "https://img.timelycoupons.com/banner/kerastase.webp",
          path: "/coupons/kerastase"
        },
        {
          img: "https://img.timelycoupons.com/banner/pet-supermarket.webp",
          path: "/coupons/pet-supermarket"
        }
    ]

  return (
    <div>
      {/*{data && data.banners && data.banners.length ? (*/}
      {/*  <HomeBanner banner={data.banners}></HomeBanner>*/}
      {/*) : null}*/}

        <HomeBanner banner={banners} isMobile={isMobile}></HomeBanner>

      <div className="content">
        {data && data.h1.length ? (
          <h1 style={{ textAlign: 'center', display: 'none' }}>{data.h1}</h1>
        ) : null}

          {data && data.festivePromos ? (
              <section className={styles.select}>
                  {data.festivePromos.title && data.festivePromos.title.length ? (
                      <div className={styles.title_container}>
                          <h2 className={styles.title}>{data.festivePromos.title}</h2>
                      </div>
                  ) : null}
                  {data.festivePromos.items && data.festivePromos.items.length ? (
                      <LogoListType1 list={data.festivePromos.items}></LogoListType1>
                  ) : null}
              </section>
          ) : null}

        {data && data.featuredStores && data.featuredStores.items.length ? (
          <section className={styles.select}>
            {data.featuredStores.title ? (
              <div className={styles.title_container}>
                <h2 className={styles.title}>{data.featuredStores.title}</h2>
              </div>
            ) : null}

            {<LogoListType2 list={data.featuredStores.items}></LogoListType2>}
          </section>
        ) : null}

          {data && data.todayNewStores && data.todayNewStores.items.length ? (
              <section className={styles.select}>
                  {data.todayNewStores.title ? (
                      <div className={styles.title_container}>
                          <h2 className={styles.title}>{data.todayNewStores.title}</h2>
                      </div>
                  ) : null}
                  {<LogoListType2 list={data.todayNewStores.items}></LogoListType2>}
              </section>
          ) : null}


          {data && data.trendingPromos ? (
              <section className={styles.select}>
                  {data.trendingPromos.title && data.trendingPromos.title.length ? (
                      <div className={styles.title_container}>
                          <h2 className={styles.title}>{data.trendingPromos.title}</h2>
                      </div>
                  ) : null}
                  {data.trendingPromos.items && data.trendingPromos.items.length ? (
                      <CouponType3 list={data.trendingPromos.items} isMobile={isMobile}></CouponType3>
                  ) : null}
              </section>
          ) : null}

        {data && data.faq && data.faq.length ? (
          <section className={styles.select}>
            <div className={styles.title_container}>
              <h2 className={styles.title}>FAQ</h2>
            </div>

            <TextListType1 list={data.faq}></TextListType1>
          </section>
        ) : null}

      </div>
    </div>
  )
}
